import { useRef, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Switch2Icon } from '../icons/small';
import { TokenAmount, chainflipAssetMap, formatWithCommas } from '../utils';
import { type ChainflipAsset } from '../utils/chainflip';

export const Rate = ({
  depositAmount,
  sourceAsset,
  egressAmount,
  destinationAsset,
  className = 'font-aeonikMono',
}: {
  depositAmount: string;
  sourceAsset: ChainflipAsset;
  egressAmount: string;
  destinationAsset: ChainflipAsset;
  className?: string;
}) => {
  const [showInverseRate, setShowInverseRate] = useState(false);
  const initialRender = useRef(true);

  const onSwitch = () => {
    initialRender.current = false;
    setShowInverseRate(!showInverseRate);
  };

  const switchAnimate = () => {
    if (initialRender.current) return 'none';
    if (!showInverseRate) return 'flash';
    return 'flash2';
  };

  const calculateRate = (): string | null => {
    const deposit = TokenAmount.fromAsset(depositAmount, sourceAsset);
    const egress = TokenAmount.fromAsset(egressAmount, destinationAsset);
    return showInverseRate
      ? formatWithCommas(deposit.ratio(egress).toFixed(8))
      : formatWithCommas(egress?.ratio(deposit).toFixed(8));
  };

  return (
    <motion.button
      animate={switchAnimate()}
      variants={{
        none: { opacity: 1 },
        flash: { opacity: [0, 1] },
        flash2: { opacity: [0, 1] },
      }}
      transition={{ duration: 0.3 }}
      className={classNames(
        `flex items-center gap-x-1 text-cf-light-3 transition ease-in hover:text-white`,
        className,
      )}
      onClick={onSwitch}
    >
      <span>1</span>{' '}
      {showInverseRate
        ? chainflipAssetMap[destinationAsset].symbol
        : chainflipAssetMap[sourceAsset].symbol}
      <Switch2Icon className="cursor-pointer" />
      <span>{calculateRate()}</span>{' '}
      {showInverseRate
        ? chainflipAssetMap[sourceAsset].symbol
        : chainflipAssetMap[destinationAsset].symbol}
    </motion.button>
  );
};
